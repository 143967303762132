
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Guide1 from '@/components/static/guide1.vue'
import ModalWindow from '@/components/organisms/ModalWindow.vue'

export default defineComponent({
  components: {
    Guide1,
    ModalWindow,
  },
  props: {
  },
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale
    return {
      lang,
    }
  },
})
