import { Store } from 'vuex'
import { createTitleLabel, createTitleLabelOfMyCollection } from '@/domain/item/ItemListView/titleLabel'
import { getItemListViewCollection } from '@/domain/item/ItemListView/collectionLabel'
import { getItemListViewContent, getItemListViewContentOfMyCollection, getItemListViewSpot } from '@/domain/item/ItemListView/contentLabel'

export const useItemListViewText = (item: any, store: Store<any>, lang: string) => {
  return {
    itemListViewContent: getItemListViewContent(item, store, lang),
    itemListViewCollection: getItemListViewCollection(item, store, lang),
    itemListViewTitle: createTitleLabel(item),
    itemListViewSpot: getItemListViewSpot(item),
  }
}

export const useItemListViewTextOfMyCollection = (item: any, store: Store<any>, lang: string) => {
  return {
    itemListViewContent: getItemListViewContentOfMyCollection(item, store, lang),
    itemListViewTitle: createTitleLabelOfMyCollection(item),
  }
}
