import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"white-space":"pre-line"} }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.ooc.text1')) + " ", 1),
    _createElementVNode("a", {
      href: _ctx.$t('link.transmissionServiceForIndividuals'),
      rel: "noopener",
      class: "level-left is-mobile",
      style: {"display":"inline"}
    }, _toDisplayString(_ctx.$t('guidance.thMethod.ooc.text2')), 9, _hoisted_2),
    _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.ooc.text3')) + " ", 1),
    _createElementVNode("a", {
      href: _ctx.$t('link.transmissionServiceForLibraries'),
      rel: "noopener",
      class: "level-left is-mobile",
      style: {"display":"inline"}
    }, _toDisplayString(_ctx.$t('guidance.thMethod.ooc.text4')), 9, _hoisted_3),
    _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.ooc.text5')), 1)
  ]))
}