import StringKeyObject from '@/data/@types/StringKeyObject'
const state: StringKeyObject = {
  // 図書
  A00001: {
    branch: 'book-close',
    leaf: 'book-open',
  },
  // 雑誌
  A00002: {
    branch: 'book-close',
    leaf: 'book-open',
  },
  // 古典籍資料
  A00003: {
    branch: 'book-close',
    leaf: 'book-open',
  },
  // 博士論文
  A00014: {
    branch: 'book-close',
    leaf: 'document',
  },
  // 官報
  A00015: {
    branch: 'gp',
    leaf: 'gp',
  },
  // 憲政資料
  A00017: {
    branch: 'book-close',
    leaf: 'book-open',
  },
  // 日本占領関係資料
  A00016: {
    branch: 'book-close',
    leaf: 'book-open',
  },
  // プランゲ文庫
  A00019: {
    branch: 'book-close',
    leaf: 'book-open',
  },
  // 録音・映像関係資料
  A00121: {
    branch: 'video',
    leaf: 'video',
  },
  // 歴史的音源
  A00024: {
    branch: 'music',
    leaf: 'music',
  },
  // 地図
  A00152: {
    branch: 'map',
    leaf: 'map',
  },
  // 特殊デジタルコレクション
  A00150: {
    branch: 'file',
    leaf: 'file',
  },
  // 他機関デジタル化資料
  A00122: {
    branch: 'file',
    leaf: 'file',
  },
  // 点字データ
  A00084: {
    branch: 'braille',
    leaf: 'braille',
  },
  // パッケージ系電子出版物
  A00162: {
    branch: 'file',
    leaf: 'file',
  },
  // 電子書籍・電子雑誌
  B00000: {
    branch: 'document',
    leaf: 'document',
  },
  // 新聞
  A00022: {
    branch: 'document',
    leaf: 'document',
  },
  // 日系移民関係資料
  A00173: {
    branch: 'book-close',
    leaf: 'book-open',
  },
}

const defaultCollection = 'A00001'
/**
 * 使い方
 * 1. store.getters.CollectionFamilyを引数に渡すことで初期化
 * 2. 初期化した関数に引数を渡すとアイコンクラス名を返す
 *   - collections = item.content.collections
 *   - type        = item.content.type
 *
 * example
 *     const initialize = placeholderImageIcon(store.getters.CollectionFamily) // 1.
 *     const placeholderIconClass = initialize(item.content.collections, item.content.type) // 2.
 *     console.log(placeholderIconClass) // stateから特定のアイコンクラス名を取得する
 */
export default function (CollectionFamily: any) {
  const hasCollectionId = (collections: string[]) => {
    return collections[0] || defaultCollection
  }

  return (collections: string[], type: 'branch' | 'leaf') => {
    if (!collections) return state[defaultCollection][type]
    if (state[collections[0]]) return state[collections[0]][type]
    const hasId = hasCollectionId(collections)
    const matchCollections = CollectionFamily(hasId)
    const isCollectionId = matchCollections.reduce((r: string | false, v: StringKeyObject) => {
      if (state[v.collectionId]) r = state[v.collectionId]
      return r
    }, false)

    return isCollectionId ? isCollectionId[type] : state[defaultCollection][type]
  }
}
