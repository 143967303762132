
import { computed, defineComponent, ref } from 'vue'
import AppLabelPlaces from '../atoms/AppLabelPlaces.vue'
import AppIconPlaces from '../atoms/AppIconPlaces.vue'
import AppCheckbox from '../atoms/AppCheckbox.vue'
import DssIcon from '../atoms/DssIcon.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import BookMarkButton from '../molecules/BookMarkButton.vue'
import PlaceholderImage from '../atoms/PlaceholderImage.vue'
import GuideModal from '@/components/organisms/GuideModal.vue'
import { useStore } from 'vuex'
import { useItemListViewText } from '@/domain/item/ItemListView'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { ruleToType, getPermissionColor } from '@/data/Permission'
import CloserController from '../molecules/CloserController.vue'
import { Content } from '@/data/@types/Content'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import { getMetadataLabel } from '@/domain/snippet/metadataLabel'
import { ContentMatch } from '@/data/@types/FulltextSearchResult'
import placeholderImageIcon from '@/domain/item/ItemListView/placeholderImageIcon'
import { Index } from '@/data/@types/Index'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { getAvailableStateText } from '@/data/AccessRestrictions'
import { sanitizeString } from '@/helpers/util/escapeCharacterUtils'
import { getItemListViewAllCollectionsForSearch } from '@/domain/item/ItemListView/collectionLabel'

/**
 * 検索結果一覧の1アイテムを表示するコンポーネント
 * 親コンポーネントでfor文などで複数指定するように利用する。
 */
export default defineComponent({
  name: 'ItemListView',
  components: {
    AppLabelPlaces,
    AppIconPlaces,
    AppCheckbox,
    DssIcon,
    ButtonIcon,
    BookMarkButton,
    PlaceholderImage,
    GuideModal,
    CloserController,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
    index: {
      type: Number,
      default: 0,
    },
    displayMode: {
      type: String,
      default: 'list',
    },
  },
  setup (props) {
    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

    const store = useStore()
    const route = useRoute()
    const isRekion = route.meta.isRekion

    const hasThumbnail = ref<boolean>(true)
    const notThumbnail = () => {
      hasThumbnail.value = false
    }

    const showSnippet = ref(false)

    const snippetsLength = computed(() =>
      getTocSnippet.value.length + highlightSnippetArray.value.length + fulltextSnippetList.value?.length
    )

    const closerText = computed(() =>
      snippetsLength.value >= 10
        ? i18n.t('label.snippetCloserTextOverTen')
        : `${i18n.t('label.snippetCloserTextUnderTenPrefix')} ${snippetsLength.value} ${i18n.t('label.snippetCloserTextUnderTenSuffix')}`
    )

    /** スニペットを探索する処理。ヒットがなければ''が返却される */
    const searchSnippet = (meta: any, keyword: string): string => {
      if (!Array.isArray(meta)) return ''
      // if (keyword === '') return ''
      const hit = meta.find(text => text.indexOf(keyword) !== -1)
      return hit || '' // undefindの場合は''が返却される
    }

    /** キーワードをスペースで分割する */
    const splitKeyword = (keyword: string): string[] => {
      const sp = ' '
      const FullWidthSpaceCharactor = '\u3000' // 全角スペース
      const partialSearchSpecialCharactor = '*'
      const perfectSearchSpecialCharactor = '\uff0f' // 全角スラッシュ
      const phraseSearchSpecialCharactor = '"'
      return keyword
        .replaceAll(FullWidthSpaceCharactor, sp) // 全角スペースで分割する
        .replaceAll(partialSearchSpecialCharactor, sp) // 部分一致用の文字「*」で分割する
        .replaceAll(perfectSearchSpecialCharactor, '') // 完全一致用の文字「／」は無視する
        .replaceAll(phraseSearchSpecialCharactor, '') // フレーズ検索用の文字「"」は無視する
        .split(sp)
        .filter(Boolean)
    }

    const addHighlight = (hitText: string, keyword: string): string => {
      if (!hitText) return hitText
      // XSS対策のため、'<'と'>'をエスケープする
      let result = sanitizeString(hitText)
      if (!keyword) return result
      const keywords: string[] = splitKeyword(keyword)
      for (const text of keywords) {
        // 空白文字はハイライトさせない
        if (text === '') continue
        // 論理演算子以外をハイライトさせる
        if (['AND', 'OR', 'NOT'].includes(text)) continue
        result = result.replaceAll(text, '<em>' + text + '</em>')
      }
      return result
    }

    const i18n = useI18n()
    const lang = i18n.locale
    const { itemListViewContent, itemListViewTitle } = useItemListViewText(props.item, store, lang.value)

    /** コレクション一覧 */
    const itemListViewCollection = computed(() => {
      const collectionList = getItemListViewAllCollectionsForSearch(props.item, store, lang.value)
      if (!Array.isArray(collectionList)) return ''
      return collectionList.join('　') + '\r\n'
    })

    /** 入力した検索条件 */
    const requestQuery = computed(() => store.getters.requestQuery)

    /** メタデータマスタからIDを取得 */
    const getMetadataId = (key: string) => {
      const metaData = store.getters.MetadataObjectByField(key)
      return metaData.id
    }

    /** ヒットしたメタデータ項目を取得 */
    const highlightSnippetArray = computed(() => {
      const maxSnippetCount = 3 // 目次・本文以外のスニペットの行数
      if (!requestQuery.value.keyword) return []
      // pidから自然数部分だけ抽出する。prefixがついていない場合はそのまま利用する。
      const array = []
      const meta = props.item.content.meta

      // メタデータマスタのIDでソート
      const sortedKeys = Object.keys(meta).sort((a, b) => getMetadataId(a) - getMetadataId(b))
      const collection = getParentCollectionId(props.item.content.collections[0])
      for (const key of sortedKeys) {
        if (array.length >= maxSnippetCount) break // 3件まで
        if (!key.endsWith('ck') && !key.endsWith('ct')) continue // keyword検索対象のIDのみ
        // 一覧に表示しているメタ項目はスニペット領域には表示しない
        if (getDisplayMetaList(collection).includes(key)) continue
        let hitText = ''
        // 分割したキーワードにヒットするメタを表示する。
        for (const text of splitKeyword(requestQuery.value.keyword)) {
          hitText = searchSnippet(meta[key], text)
          if (hitText !== '') break // キーワード内でいずれかhitしたタイミングで終了する
        }
        if (hitText === '') continue

        const metaDataLabel = getMetadataLabel(store.getters, props.item.content.collections[0], key)
        array.push({
          key: metaDataLabel[lang.value as 'ja' | 'en'],
          value: addHighlight(hitText, requestQuery.value.keyword),
        })
      }
      return array
    })

    const DisplayMetaListByCollection: any = {
      // 古典籍資料
      A00003: ['0001Dtct', '0003Dtct', '0007Dtct', '0010Dtct', '0020Dtct', '0058Dod', '0059Dk', '0269Dod', '0270Dt', '0271Dt', '0272Dt'],
      // 錦絵
      A00004: ['0001Dtct', '0003Dtct', '0007Dtct', '0010Dtct', '0020Dtct', '0269Dod', '0270Dt', '0058Dod', '0059Dk', '0271Dt', '0272Dt'],
      // 官報
      A00015: ['0001Dtct', '0003Dtct', '0007Dtct', '0010Dtct', '0020Dtct', '0269Dod', '0270Dt', '0058Dod', '0059Dk', '0271Dt', '0272Dt'],
      // 日本占領関係資料
      A00016: ['0001Dtct', '0003Dtct', '0007Dtct', '0269Dod', '0270Dt', '0058Dod', '0059Dk'],
      // 憲政資料
      A00017: ['0001Dtct', '0007Dtct', '0010Dtct', '0058Dod', '0059Dk', '0074Dt', '0269Dod', '0270Dt', '0371Dkck'],
      // 歴史的音源
      A00024: ['0001Dtct', '0003Dtct', '0007Dtct', '0010Dtct', '0020Dtct', '0058Dod', '0059Dk', '0269Dod', '0270Dt', '0271Dt', '0272Dt', '0372Dt'],
      // 録音・映像関係資料 > 脚本
      A00123: ['0001Dtct', '0007Dtct', '0010Dtct', '0018Dtct', '0020Dtct', '0269Dod', '0270Dt', '0058Dod', '0059Dk'],
      // 録音・映像関係資料 > 手稿譜
      A00124: ['0001Dtct', '0007Dtct', '0010Dtct', '0058Dod', '0059Dk', '0269Dod', '0270Dt'],
      // // 他機関デジタル化資料 > 科学映像
      A00126: ['0001Dtct', '0007Dtct', '0010Dtct', '0020Dtct', '0058Dod', '0059Dk', '0269Dod', '0270Dt'],
      // デフォルト
      default: ['0001Dtct', '0003Dtct', '0007Dtct', '0010Dtct', '0020Dtct', '0058Dod', '0059Dk', '0269Dod', '0270Dt', '0271Dt', '0272Dt'],
    }

    /**
     * 画面に表示しているメタ項目のリストを返す
     */
    const getDisplayMetaList = (collection: string) => {
      if (Object.keys(DisplayMetaListByCollection).includes(collection)) {
        return DisplayMetaListByCollection[collection]
      }
      return DisplayMetaListByCollection.default
    }

    /**
     * コレクションIDの親コレクションIDを返す
     * 引数が親コレクションの場合は自身を返す
     */
    const getParentCollectionId = (collectionId: string): string => {
      // 錦絵、録音・映像関係資料 > 脚本、録音・映像関係資料 > 手稿譜、他機関デジタル化資料 > 科学映像
      const exceptionCollectionId = ['A00004', 'A00123', 'A00124', 'A00126']
      if (exceptionCollectionId.includes(collectionId)) return collectionId
      return store.getters.CollectionFamily(collectionId)[1]?.collectionId || 'default'
    }

    /**
     * スニペットを表示するかどうかを判定する
     */
    const isSnippetDisplayed = computed((): boolean => snippetsLength.value > 0)

    const newLineCode = '\r\n'
    const permissionText = computed(() => getAvailableStateText(permissionRule.value, location.value, isLoggedIn.value, isMiddlePersend.value, newLineCode, i18n))

    const permissionRule = computed(() => {
      const rule = props.item.content.permission ? props.item.content.permission.rule ? props.item.content.permission.rule : 'internet' : ''
      return ruleToType(rule)
    })

    const permissionColor = computed(() => {
      return getPermissionColor(permissionRule.value, isRekion)
    })

    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const location = computed(() => store.getters.location)
    const isPersendOrLibsend = computed(() => store.getters.isPersendOrLibsend)
    const isMiddlePersend = computed(() => store.getters.isMiddlePersend)
    const isModalShow = ref(false)

    const questionmarkFlag = computed(() => {
      return (permissionRule.value === 'ooc') && (!isPersendOrLibsend.value) && isMiddlePersend.value && (location.value !== 'ndl' && location.value !== 'kss')
    })

    /**
     * 目次のスニペット情報を返却
     */
    const getTocSnippet = computed(() => {
      const highlight = props.item.content.highlightFields
      // ESハイライト機能を利用しているため、itemインデックスの目次データの位置がkeyになっている
      if (highlight && Object.keys(highlight).length && highlight.length) {
        const tocSnippetList: any[] = []
        highlight.forEach((toc: Index, i: number) => {
          if (highlightSnippetArray.value.length + i >= 10) return
          const bundleIndex = getBundleIndex(toc.contentId)
          const contentIndex = getContentIndex(getBundleIndex(toc.contentId), toc.contentId)
          const canAccessContent = toc.contentId !== null && bundleIndex !== null && contentIndex !== null
          tocSnippetList.push({
            key: i18n.t('label.index'),
            value: toc.index,
            path: canAccessContent ? makeUpdatedPidString(props.item.content.pid) + '/' + (bundleIndex + 1) + '/' + (contentIndex! + 1) : null,
          })
        })
        return tocSnippetList
      }
      return []
    })

    /**
     * スニペット情報に紐づくコンテンツのパラメーターを返却
     * @param cid
     */
    const getBundleIndex = (cid: string | undefined) => {
      if (!props.item.content.contentsBundles) return null
      const bundleIndex = props.item.content.contentsBundles.findIndex((contentsBundle: ContentsBundle) => {
        if (contentsBundle.contents === null) return false
        return contentsBundle.contents.some(content => content.id === cid)
      })
      return bundleIndex === -1 ? null : bundleIndex
    }

    /**
     * コンテンツのインデックス番号を返却
     * @param bundleIndex
     * @param cid
     */
    const getContentIndex = (bundleIndex: number, cid: string | undefined) => {
      if (bundleIndex === null) return null
      const contents: Array<Content> = props.item.content.contentsBundles[bundleIndex].contents
      if (!contents) return null
      return contents.findIndex(v => v.id === cid)
    }

    /**
     * スニペット情報に紐づくコンテンツのパラメーターを返却
     * @param bundleIndex
     * @param contentIndex
     */
    const getItemParam = (bundleIndex: number, contentIndex: number) => {
      if (contentIndex === null) {
        return `/${makeUpdatedPidString(props.item.content.pid)}?keyword=${requestQuery.value.keyword}`
      }
      // URLでのコンテンツ指定は1オリジンのため
      return `/${makeUpdatedPidString(props.item.content.pid)}/${bundleIndex + 1}/${contentIndex + 1}?keyword=${requestQuery.value.keyword}`
    }

    /**
     * 本文スニペットのラベル情報を返却する
     * @param bundleIndex
     * @param contentIndex
     */
    const getFulltextLabel = (bundleIndex: number, contentIndex: number | null) => {
      if (bundleIndex === null || contentIndex === null) return null
      const contentBundle = props.item.content.contentsBundles[bundleIndex]
      const content = contentBundle.contents[contentIndex]
      if (contentBundle.type === 'file' || contentBundle.type === 'content') {
        return content.originalFileName || contentIndex + 1 + ' ' + i18n.t('label.frame')
      }
      return contentIndex + 1 + ' ' + i18n.t('label.frame')
    }

    /**
     * スニペット検索結果を持つpidであればスニペットを返却する
     * @param pid
     */
    const fulltextSnippetList = computed(() => {
      // pidから自然数部分だけ抽出する。prefixがついていない場合はそのまま利用する。
      const fullTextArrayData = store.getters.getFulltextSnippet(props.item.content.pid)
      if (!fullTextArrayData?.contents) return []

      const fullTextList: any[] = []
      const highlightFullText = (m: any): string => {
        // XSS対策のため、'<'と'>'をエスケープする
        const head = sanitizeString(m.head.slice(-10))
        const word = sanitizeString(m.word)
        const tail = sanitizeString(m.tail)
        return head + '<em>' + word + '</em>' + tail
      }
      fullTextArrayData.contents.forEach((content: ContentMatch) => {
        content.matches && content.matches.forEach((m: any) => {
          if (getTocSnippet.value.length + highlightSnippetArray.value.length + fullTextList.length >= 10) return
          const bundleIndex = getBundleIndex(content.cid)
          const contentIndex = getContentIndex(getBundleIndex(content.cid), content.cid)
          fullTextList.push({
            label: getFulltextLabel(bundleIndex, contentIndex),
            value: highlightFullText(m),
            bundleIndex: bundleIndex,
            contentIndex: contentIndex,
          })
        })
      })
      return fullTextList
    })

    const placeholderType = computed(() => {
      const placeholderImage = placeholderImageIcon(store.getters.CollectionFamily)
      const collections: string[] = props.item.content.collections
      const type = props.item.content.type

      return placeholderImage(collections, type)
    })

    return {
      meta: props.item.content.meta,
      collections: props.item.content.collections,
      permissionText,
      permissionColor,
      itemIndex: props.index + 1,
      content: props.item.content,
      notThumbnail,
      hasThumbnail,
      BASE_URL,
      placeholderType,
      isSnippetDisplayed,
      fulltextSnippetList,
      highlightSnippetArray,
      itemListViewTitle,
      itemListViewCollection, // コレクションにはキーワードをあてる必要がない
      itemListViewContent: addHighlight(itemListViewContent, requestQuery.value.keyword),
      requestQuery,
      isRekion,
      questionmarkFlag,
      permissionRule,
      isModalShow,
      closerText,
      snippetsLength,
      showSnippet,
      getItemParam,
      getTocSnippet,
      makeUpdatedPidString,
    }
  },
})
