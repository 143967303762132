import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "window-context" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Guide1 = _resolveComponent("Guide1")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createBlock(_component_ModalWindow, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    heading: _ctx.$t('guidance.accessRestriction.ooc'),
    class: "window-modal window-login-form"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createVNode(_component_Guide1, { lang: _ctx.lang }, null, 8, ["lang"])
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}